<template>
  <div>
    <div
      variant="light"
      class="justify-content-end container alert alert-white alert-shadow"
      style="padding-top: 10px"
    >
      <div class="row">
        <b-col md="3" class="mb-2">
          <b-container fluid>
            <b-col><label>Loại GD cấp phát:</label></b-col>
            <b-col>
              <b-form-select v-model="searchData.distributeType">
                <option
                  v-for="(item, id) in listDistributeType"
                  :value="id"
                  :key="id"
                >
                  {{ item }}
                </option>
              </b-form-select>
            </b-col>
          </b-container>
        </b-col>
        <b-col md="3" class="mb-2">
          <b-container fluid>
            <b-col><label :for="`startDate`">Ngày bắt đầu :</label></b-col>
            <b-col>
              <b-form-input
                :id="`type-date`"
                :type="`date`"
                v-model="searchData.startDate"
              ></b-form-input>
            </b-col>
          </b-container>
        </b-col>
        <b-col md="3" class="mb-2">
          <b-container fluid>
            <b-col><label :for="`endDate`">Ngày kết thúc :</label></b-col>
            <b-col>
              <b-form-input
                :id="`type-date`"
                :type="`date`"
                v-model="searchData.endDate"
              ></b-form-input>
            </b-col>
          </b-container>
        </b-col>
        <b-col md="3" class="mb-2">
          <b-container fluid>
            <b-col><label>Số điện thoại</label></b-col>
            <b-col>
              <b-form-input v-model="searchData.userPhone"></b-form-input>
            </b-col>
          </b-container>
        </b-col>

        <b-col md="3" class="mb-2">
          <b-container fluid>
            <b-col><label>Voucher ID</label></b-col>
            <b-col>
              <b-form-input v-model="searchData.voucherId"></b-form-input>
            </b-col>
          </b-container>
        </b-col>

        <b-col md="3" class="mb-2">
          <b-container fluid>
            <b-col><label>Action Refer ID</label></b-col>
            <b-col>
              <b-form-input v-model="searchData.referId"></b-form-input>
            </b-col>
          </b-container>
        </b-col>
      </div>
      <div class="row col-md-12 justify-content-end">
        <button
          ref="submit-search"
          class="
            btn btn-elevate btn-primary
            font-weight-bold
            px-11
            py-2
            my-4
            font-size-3
          "
          @click="search()"
        >
          Search
        </button>
      </div>
    </div>
    <b-card-text v-if="errorMessage" align="center" class="text-danger"
      >{{ errorMessage }}
    </b-card-text>

    <div id="detail" class="row">
      <div class="col-md-12">
        <b-card-group deck>
          <b-card header-tag="header" footer-tag="footer">
            <template v-slot:header>
              <h6 class="mb-0">Danh sách chi tiết</h6>
            </template>
            <b-table
              ref="table"
              striped
              hover
              responsive
              caption-top
              :busy="table.loading"
              :items="table.items"
              :fields="table.fields"
              show-empty
            >
              <template #cell(distribute_type)="data">
                {{ convertDistributeType(data.value) }}
              </template>
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>...</strong>
                </div>
              </template>
              <template #empty="scope"></template>
            </b-table>
            <div class="mt-3">
              <b-pagination
                v-model="table.currentPage"
                :total-rows="table.paginate.total"
                :per-page="table.paginate.limit"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                @change="getHistory"
                align="right"
              ></b-pagination>
            </div>
          </b-card>
        </b-card-group>
      </div>
    </div>
  </div>
</template>

<script>
import Common from "@/core/mixins/common";
// import Message from "@/core/config/message.config";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Message from "@/core/config/message.config";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import voucherConfig from "@/core/config/voucher.config";

const VoucherRepository = RepositoryFactory.get("voucher");

export default {
  name: "DistributeHistory",
  mixins: [Common],
  components: {},
  data() {
    return {
      errorMessage: "",
      listDistributeType: [
        "All",
        voucherConfig.DISTRIBUTE_TYPE_SYSTEM,
        voucherConfig.DISTRIBUTE_TYPE_EVENT,
        voucherConfig.DISTRIBUTE_TYPE_LOYALTY,
        voucherConfig.DISTRIBUTE_TYPE_CAMPAIGN,
      ],
      searchData: {
        distributeType: 0,
        startDate: this.getYesterday(),
        endDate: this.getCurrentDay(),
        userPhone: "",
        voucherId: "",
        referId: "",
      },

      table: {
        fields: [
          {
            key: "id",
            label: "Id",
          },
          {
            key: "user.phone",
            label: "Số điện thoại",
          },
          {
            key: "coupon.name",
            label: "Mã giảm giá",
          },
          {
            key: "coupon_id",
            label: "Voucher ID",
          },
          {
            key: "refer_id",
            label: "Refer ID",
          },
          {
            key: "distribute_type",
            label: "Loại",
          },
        ],
        items: [],
        loading: false,
        currentPage: 1,
        paginate: {
          limit: 25,
          total: 0,
        },
      },
    };
  },
  methods: {
    search() {
      this.getHistory();
    },
    async getHistory(page) {
      try {
        this.table.loading = true;
        let params = {
          distribute_type: this.searchData.distributeType,
          start_date: this.searchData.startDate,
          end_date: this.searchData.endDate,
          page: page || 1,
          limit: this.table.paginate.limit,
        };
        if (this.searchData.userPhone) {
          params.phone = this.searchData.userPhone;
        }

        if (this.searchData.voucherId) {
          params.voucher_id = this.searchData.voucherId;
        }

        if (this.searchData.referId) {
          params.refer_id = this.searchData.referId;
        }
        const response = await VoucherRepository.getdistributeHistory(params);
        this.table.loading = false;
        const body = response.data;
        this.table.items = body.data.list;
        this.table.paginate.total = body.data.paging.total;
      } catch (e) {
        this.table.loading = false;
        return this.showError(Message.ERROR.SERVER);
      }
    },
    convertDistributeType(key) {
      switch (key) {
        case 1:
          return voucherConfig.DISTRIBUTE_TYPE_SYSTEM;
        case 2:
          return voucherConfig.DISTRIBUTE_TYPE_EVENT;
        case 3:
          return voucherConfig.DISTRIBUTE_TYPE_LOYALTY;
        case 4:
          return voucherConfig.DISTRIBUTE_TYPE_CAMPAIGN;
        default:
          return voucherConfig.DISTRIBUTE_TYPE_SYSTEM;
      }
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },
  },
  created() {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Lịch sử cấp voucher" }]);
  },
};
</script>

<style scoped></style>
